<template>
    <div>
        <CModal
                title="Create Ticket"
                :show.sync="createModal"
                @update:show="closeModal"
                centered
                size="xl"
        >
            <CRow>
                <CCol>
                    <CCard>
                        <CCardHeader>
                            <CInput style="font-size: 20px"
                                    label="Ticket Title"
                                    size="lg"
                                    horizontal
                                    v-model="ticket_title">
                            </CInput>
                        </CCardHeader>
                    </CCard>

                    <CCard>
                        <CCardHeader>
                                <CInput style="font-size: 20px"
                                        label="Ticket Reporter"
                                        size="lg"
                                        horizontal
                                        v-model="reporter">
                                </CInput>
                        </CCardHeader>
                    </CCard>

                    <CCard>
                        <CCardHeader>
                            <CCol sm="2">
                                <CSelect v-if="priorities"
                                         label="Tiket Priority"
                                         :options="priorities"
                                         :value.sync="prio_id"
                                />
                            </CCol>
                        </CCardHeader>
                    </CCard>

                    <CCard>
                        <CCardHeader>

                            <h4> Ticket Description </h4>
                        </CCardHeader>
                        <wysiwyg v-model="ticket_description"/>
                    </CCard>
                </CCol>
            </CRow>
        </CModal>
        <CRow>
            <CCol sm="6">
                <CCard>
                    <CCardHeader>
                        <CIcon name="cil-justify-center"/>
                        <strong> All Tickets </strong><small></small>
                        <CButton color="success" pill size="sm" @click="createModal=true"> Create Ticket</CButton>
                    </CCardHeader>
                    <CCardBody>
                        <CListGroup>
                            <CListGroupItem
                                    @dblclick="getDetails(ticket)"
                                    v-for="ticket in tickets_list"
                                    href="#"

                                    class="flex-column align-items-start"
                            >
                                <div class="d-flex w-100 justify-content-between">
                                    <h5 class="mb-1">[#{{ ticket.ticket_id }}] {{ticket.ticket_title}}
                                        {{ticket.status}}</h5>
                                    <small>{{ new Date(ticket.ticket_created).toDateString() }}</small>
                                </div>
                                <CBadge pill v-if="ticket.ticket_prio_name == 'Showstopper'" color="danger">
                                    {{ticket.ticket_prio_name}}
                                </CBadge>
                                <CBadge pill v-if="ticket.ticket_prio_name == 'Minor'" color="success">
                                    {{ticket.ticket_prio_name}}
                                </CBadge>
                                <CBadge pill v-if="ticket.ticket_prio_name == 'Major'" color="warning">
                                    {{ticket.ticket_prio_name}}
                                </CBadge>

                                <span style="margin-left: 50px"> Reported by {{ticket.ticket_reporter}} </span>
                            </CListGroupItem>
                        </CListGroup>
                    </CCardBody>
                </CCard>
            </CCol>

            <CCol sm="4">
                <CCard>
                    <CCardHeader>
                        <CIcon name="cil-justify-center"/>
                        <strong> By Status </strong><small></small>
                    </CCardHeader>
                    <CChartDoughnut
                            :datasets="ticket_status"
                            :labels="labels_tickets"
                    />
                    <br>
                </CCard>

                <CCard>
                    <CCardHeader>
                        <CIcon name="cil-justify-center"/>
                        <strong> By Priority </strong><small></small>
                    </CCardHeader>
                    <CChartBar
                            :datasets="graph_data"
                            :labels="labels_prio"
                    />
                    <br>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>

<script>import axios from 'axios';
import {CChartBar, CChartDoughnut} from "@coreui/vue-chartjs";
import Badges from "../notifications/Badges";

export default {
    name: 'TicketsBoard',
    components: {Badges, CChartDoughnut, CChartBar},
    computed: {
        defaultDatasets() {
            return [
                {
                    backgroundColor: [
                        '#41B883',
                        '#E46651',
                    ],
                    data: [1, 2]
                }
            ]
        }
    },
    data() {
        return {

            tickets_list: [],
            createModal: false,
            ticket_title: "",
            ticket_description: "",
            labels_tickets: [],
            reporter: "",
            priorities: [],
            prio_id: null,
            ticket_status: {
                backgroundColor: [
                    '#41B883',
                    '#E46651',
                ],
                data: []
            },
            labels_prio: [],
            graph_data: [
                {
                    backgroundColor: [
                        '#b84141',
                        '#E46651',
                        '#00D8FF',

                    ],
                    data: []
                }
            ]


        }
    },
    methods: {
        getDetails(ticket) {
            this.$router.push({path: `ticketdetails/${ticket.ticket_id}`})
        },
        closeModal(event, status, accept) {

            if (accept){
                axios.post(this.$baseURL + '/ticket', {
                    ticket_title: this.ticket_title,
                    ticket_reporter: this.reporter,
                    usr_id: this.whoamI,
                    ticket_description: this.ticket_description,
                    ticket_prio_id: this.prio_id,
                    ticket_state_id: 1
                }).then(res => {
                    res;
                    this.$toast.success("Successfully created ticket.")
                    this.getTickets()
                }).catch((error) => {
                    this.$toast.error(error);
                })
            }



        },
        getPriorities() {

            axios.get(this.$baseURL + '/ticket/priorities')
                .then(res => {

                    res.data.forEach(e => {
                        this.priorities.push({label: e.ticket_prio_name, value: e.ticket_prio_id})
                    })
                })
        },
        getTickets() {

            axios.post(this.$baseURL + '/ticket/all', {})
                .then((res) => {
                    this.tickets_list = res.data;
                    var tmp = {}
                    var tmp_2 = {}
                    res.data.forEach(e => {
                        if (e.ticket_state_name in tmp) {
                            tmp[e.ticket_state_name] = tmp[e.ticket_state_name] + 1;
                            tmp_2[e.ticket_prio_name] = tmp[e.ticket_prio_name] + 1;
                        } else {

                            tmp[e.ticket_state_name] = 1;
                            tmp_2[e.ticket_prio_name] = 1;
                        }

                        var val_list = []
                        for (let [key, value] of Object.entries(tmp)) {
                            val_list.push(value);
                            this.labels_tickets.push(key);
                        }
                        var val_list2 = []
                        for (let [key, value] of Object.entries(tmp_2)) {
                            val_list2.push(value);
                            this.labels_prio.push(key);
                        }


                        this.ticket_status = [{
                            backgroundColor: [
                                '#41B883',
                                '#E46651',
                            ],
                            data: val_list
                        }]
                        this.graph_data = [
                            {
                                backgroundColor: [
                                    '#ff0000',
                                    '#d57b6f',
                                    '#b0ccd4',
                                ],
                                data: val_list2
                            }
                        ]
                    })
                }).catch((error) => {
                console.log(error);
            })


        }
    },
    watch: {
        $route: {
            immediate: true,
            handler(route) {
                if (route.query && route.query.page) {
                    this.activePage = Number(route.query.page)
                }
            }
        }
    },
    mounted() {
        this.validateSession()
        this.getPriorities()
        this.getTickets()
    }
}
</script>
